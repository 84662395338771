<template>
  <div>
    <el-backtop :bottom="100"></el-backtop>
    <el-button round @click="back" class="btn">返回上一页</el-button>
    <div class="pdfPreview" ref="pdfPreview" id="pdfPreview" v-if="show">
      <pdf v-for="page in pageNum" :key="page" :page="page" :src="url"></pdf>
    </div>
    <el-button round @click="back" class="btn">返回上一页</el-button>
  </div>
</template>


<script>
//使用npm install的方式将包加入到项目package.json
import pdf from 'vue-pdf'

export default {
  name: 'Pdf',
  components: {
    pdf
  },
  data() {
    return {
      pdf: '',
      filePath: localStorage.getItem("preview_pdf"),
      fileName: '',
      url: localStorage.getItem("preview_pdf"),
      //懒加载页数
      pageNum: 3,
      //总页数
      numPages: '',
      height: '',
      show: true
    }
  },

  activated() {

  },
  mounted() {//实时监听滚动条的变化
    this.init();

    window.addEventListener('scroll', this.getScroll, true)
    // this.color()

    window.onscroll = (e) => {
      console.log(e.target.style)
    }
  },
  destroyed() {//注意：监听的函数要在关闭页面时销毁掉,不然会影响到其它页面
    let preview = document.getElementById('pdfPreview')
    this.$refs.pdfPreview.removeEventListener('scroll', this.getScroll)
  },
  methods: {
    color() {

      if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {

        document
          .getElementsByTagName("body")[0]
          .style.setProperty("--test", localStorage.getItem('themeOther').color);
      }

    },
    init() {
      //获取下载地址链接
      // this.url = this.filePath + '&proviewFlag=' + 'true'
      this.url = pdf.createLoadingTask(this.url);
      this.url.promise.then(pdf => {
        this.pdf = pdf
        this.numPages = pdf.numPages;
        this.show = true
        //滚动加载
        this.getScroll();
        console.log(pdf)
      });
    },
    getScroll(e) {
      console.log(document.body.scrollTop, '滚动高度')
      // const scrollTop = document.documentElement.scrollTop
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (this.fileName.indexOf('pdf') != -1) {
        this.height = 1300
      } else {
        this.height = 450
      }
      // console.log(scrollTop, scrollTop > this.height * (this.pageNum - 2), (scrollTop < this.height * (this.pageNum + 1)));
      // console.log(this.pageNum, this.height * (this.pageNum - 1), scrollTop, this.height * (this.pageNum + 1))
      if ((scrollTop > this.height * (this.pageNum - 2)) && (scrollTop < this.height * (this.pageNum + 1))) { //懒加载条件限制判断
        if (this.pageNum < this.numPages) {//做一次判断
          //达到条件后执行这个函数
          this.pageNum += 1
          // this.pdf.getPage(this.pageNum).then(page => {
          //   console.log(page,'page')
          // })

        }
      }
    },
    // 获取pdf页数
    getNumPages() {
      console.log(this.url, 'page')
      let loadingTask = pdf.createLoadingTask(this.url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(err => {
        this.numPages = 0
        console.error('pdf 加载失败', err)
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
@import "../../style/default/default.scss";

.btn {
  width: 100%;
  background: $brown;
  color: white;
}
</style>